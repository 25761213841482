<template>
    <span class="c-rating">
        <span data-js-test="rating-fill" class="c-rating--fill" :style="'width:' + percent + '%'">
        </span>
    </span>
</template>

<script>
export default {
    props: {
        percent: {
            type: Number,
            required: true,
            default: 0
        }
    }
}
</script>

<style lang="scss" scoped>
.c-icon--star, .c-rating {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 17'%3E%3Cpath fill='%23b2b0ae' fill-rule='evenodd' d='m16.6 6-4.8-.4L10 1.2a1 1 0 0 0-1.8 0L6 5.6 1.5 6a1 1 0 0 0-.6 1.8L4.5 11l-1.1 4.7a1 1 0 0 0 1.4 1L9 14.4l4.1 2.5a1 1 0 0 0 1.5-1.1l-1-4.7 3.6-3.2c.7-.6.3-1.7-.6-1.8zM9 12.4l-3.8 2.3 1-4.3L3 7.5l4.4-.4 1.7-4 1.7 4 4.4.4-3.3 2.9 1 4.3L9 12.4z'/%3E%3C/svg%3E");
}
.c-rating {
  background-repeat: repeat-x;
  display: inline-block;
  height: 10px;
  width: 50px;
  text-align: left;
}

.c-rating, .c-rating .c-rating--fill {
    background-size: 10px;
}
.c-rating--fill.c-ratings--fill-18 {
    width: 40%;
}
.c-rating--fill {
    background-repeat: repeat-x;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 100%;
    text-align: left;
}
.c-icon--star:hover, .c-icon--star:hover~label, .c-rating--fill, .c-ratingSelectable-input:checked+label, .c-ratingSelectable-input:checked~input+label {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 18 18'%3E%3Cdefs%3E%3Cpath id='a' d='m12 17.3 4.8 2.8a.7.7 0 0 0 1-.7L16.5 14l4.3-3.7a.7.7 0 0 0-.4-1.2l-5.6-.5-2.2-5.1a.7.7 0 0 0-1.2 0L9.2 8.6l-5.6.5a.7.7 0 0 0-.4 1.2L7.5 14l-1.3 5.4a.7.7 0 0 0 1 .7l4.8-2.8z'/%3E%3C/defs%3E%3Cuse fill='%23FF8000' fill-rule='evenodd' transform='translate(-3 -3)' xlink:href='%23a'/%3E%3C/svg%3E");
}
</style>
