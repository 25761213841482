<template lang="pug">
  div.bg-gray
    div.pr-5.thirstlevel(style="top:100px")
      v-row.flex-center
        v-col.pr-0
          bread-crumbs( :paramCOD="$route.params.paramCOD")
        v-col.col-filters.col-filters-big-home.pb-0.pt-0
          div.filters.py-0
            div.filter.filtroDate
              range-date



    div
      div.margin-bottom-0.gridFull
        v-row#headerWithPagination(v-if="comments")
          v-col.contentOutPadding.text-left(md="8")
            span.totalComments {{ totalComments }} #[translate Comments]
          v-col.contentOutPadding.text-right#pagination(md="4")
            div
              span.numberOfPage {{ page }} #[translate of] {{ pageCount }} #[translate pages]
              v-pagination#paginatorComments(v-if="comments"
                v-model="page"
                :length="pageCount"
                @input="handlePageChange"
              )

        v-row.flex-center
          v-col(md="12")
            div.filters
              span.downloadExcel.mr-5(@click="downloadExcelCustom()")
                img(:src="imgCommons.download" width="10")
              span.hide.labelFilter #[translate Comment type]:
              div.hide.filter
                multi-select.inline-block(:options="commentTypes" :itemsChecked="commentTypesSelected" @checked="onCheckCommentTypes")
              span.hide.labelFilter #[translate Language]:
              div.hide.filter
                multi-select.inline-block(:options="commentLanguage" :itemsChecked="commentLanguageSelected" @checked="onCheckCommentLanguage")
              span.hide.labelFilter #[translate Reason]:
              div.hide.filter
                multi-select.inline-block.optionsWidth(:options="reasonTypes" :itemsChecked="reasonTypesSelected"  @checked="onCheckReasonTypes")
              
              div.filter
                span.labelFilter #[translate Type of Consumption]:
                multi-select.inline-block(:options="consumptionTypes" :itemsChecked="consumptionTypesSelected"   @checked="onCheckConsumptionTypes")
        v-row.contentOutPadding
          v-col(md="12").contentOutPadding.tablaGR
            v-data-table#tableComments(
              :headers="headers"
              :items="comments"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              class="elevation-1"
              :loading="loading"
              :loading-text="loadingtext"
              :server-items-length="totalComments"
              @page-count="pageCount = $event")
              template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
                span(v-if="header.value === 'sentiment'")
                  span {{ header.text }}
                  .boxOrder
                    <sort :orderBy="'rating_recommendation'" :orderByActual="ordering" @sort="setOrder($event)" />                            
                span(v-else-if="header.value === 'source_date'")
                  span {{ header.text }}
                  .boxOrder
                    <sort :orderBy="'source_date'" :orderByActual="ordering" @sort="setOrder($event)" />  
                span(v-else) {{ header.text }}
              template(v-slot:item.sentiment="{ item }")
                div.text-center
                  div.d-flex.justify-center.items-center.gap-0_5em
                    span.tipo(dark :style="'background-color:' + getColorNoteGPS(item.rating_recommendation,'color')")
                    span {{ item.rating_recommendation }}                  
              template(v-slot:item.text="{ item }")
                div#block-comment
                  div.pl-2.text-13.mb-1
                    div Cliente:
                      span.text-semibold {{ item.user_name ? item.user_name : 'N/D' }} {{ item.email ? `(${item.email})` : '' }}
                  p.comentario.pl-2.text-12(id="parraf" dark) {{ item.text }}
                  div.categories(v-if="item.categories && item.categories.length > 0")
                    span.category(v-for="category in item.categories" :class="'cat-' + category.id") {{ category.name }}
                  div.d-flex.mt-2
                    note-question-poll.mr-2( v-for="(note, index) in Object.entries(item).filter((e) => e[0].indexOf('rating') > -1 && e[1] !== null && e[0] != $config.featureConfig.keyQuestionNPS) " :data="note")
              template(v-slot:item.center.name="{ item }")
                router-link.btnCentro.link-text(v-if="typeNode !== 'center'" :to="goItemNextLevelCustom(item)") {{ item.center_name }}
                span.btnCentro(v-else) {{ item.center_name }}
              template(v-slot:item.survey_type="{ item }")
                span.text-origin( dark) {{ getNameComsuptionTypes(item.survey_type) }}
              template(v-slot:item.source_date="{ item }")
                span.text-date( dark) {{ item.source_date | moment("DD/MM/YYYY") }}
        v-row#itemsPagePagination
          v-col.text-left(md="8")
            span #[translate Rows per page]:
            v-select.selectItemsPerPage(v-model="itemsPerPage" :items="numbersItemsPerPage"
              :label="itemsPerPage.toString()"
              max-with="50"
              @change="handlePageSizeChange"
              solo)

          v-col.text-right(md="4")
            v-pagination.paginationFooter(v-if="comments"
              v-model="page"
              :length="pageCount"
              @input="handlePageChange"
            )
</template>

<script>
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'
import RangeDate from '@/components/filters/RangeDates.vue'
import MultiSelect from '@/components/filters/Multipleselect.vue'
import SrvSatisfaction from '@/services/ranking-satisfaction.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import NoteQuestionPoll from '@/components/satisfaction/NoteQuestionPoll.vue'
import Sort from "@/components/common/Sort.vue"

export default {
  components: {MultiSelect, RangeDate, BreadCrumbs, NoteQuestionPoll, Sort}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  mixins: [commonMixins],
  data(){
    return {
      comments: [], // COLECCION DE COMENTARIOS
      page: 1, // PAGINA EN LA QUE ESTAMOS
      pageCount: 0, // PAGINAS TOTALES
      itemsPerPage: 25, // ELEMENTOS POR PAGINA
      img: {
        sort: require("@/assets/img/mini_arrow_shack_disable.svg"),
        sortActive: require("@/assets/img/mini_arrow_shack_active.svg"),
      },
      commentTypes: [ // ELEMENTOS FILTRO POR TIPO DE COMENTARIO
        {value: 1, text: this.$gettext('Positive'), box:'#89C445'},
        {value: 2, text: this.$gettext('Negative'), box:'#EC4444'},
        {value: 3, text: this.$gettext('Sin contratiempo'), box:'#F0F0F0'},
        {value: 4, text: this.$gettext('Con contratiempo'), box:'#F0F0F0'}
      ],
      commentTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
      commentLanguage: [ // ELEMENTOS FILTRO POR IDIOMA
        {value: 1, text: this.$gettext('Spanish')},
        {value: 2, text: this.$gettext('Catalan')},
        {value: 3, text: this.$gettext('English')},
        {value: 4, text: this.$gettext('Portuguese')}
      ],
      commentLanguageSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
      reasonTypes: [ // ELEMENTOS DEL FILTRO MOTIVO
        {value: 1, text: this.$gettext('Personal care')},
        {value: 2, text: this.$gettext('Speed')},
        {value: 3, text: this.$gettext('Puntuality')},
        {value: 4, text: this.$gettext('Product')},
        {value: 5, text: this.$gettext('Cleaning')},
        {value: 6, text: this.$gettext('Price')},
        {value: 7, text: this.$gettext('Others')}
      ],
      reasonTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
      consumptionTypes: [ // ELEMENTOS DEL FILTRO TIPOS DE CONSUMO
        {value: 'Room', text: this.$gettext('Sala')},
        {value: 'Delivery', text: this.$gettext('Delivey')},
        {value: 'Corporate', text: this.$gettext('Corporativo')}
      ],
      consumptionTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
      numbersItemsPerPage: [25,50,100], // OPCIONES DE NÚMERO DE COMENTARIOS POR PAGINA
      ordering: "-source_date",
      headers: [ // CABECERAS DE LA TABLE COMENTARIOS
        {
          text: this.$gettext('  NPS  '),
          align: 'start',
          sortable: false,
          value: 'sentiment',
          width: '8%'
        },
        { text: this.$gettext('COMMENT'), value: 'text', sortable: false, width: '65%' },
        { text: this.$gettext('RESTAURANT'), value: 'center.name', sortable: false, width: '9%'},
        { text: this.$gettext('ORIGIN'), value: 'survey_type', sortable: false, width: '9%'},
        { text: this.$gettext('DATE'), value: 'source_date', sortable: false, width: '9%'},
      ],
      options: {}, // OPCIONES DE V-TABLE
      loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      loadingtext: this.$gettext('Loading comments'),
      totalComments: 0, // CONTADOR TOTAL DE COMENTARIOS
      totalWidth: 680
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  created(){

    this.$store.commit(types.UPDATE_SECTION,3)
    this.commentTypesSelected = this.$store.getters.getCommentTypesSelected
    this.commentLanguageSelected = this.$store.getters.getCommentLanguageSelected
    this.reasonTypesSelected = this.$store.getters.getReasonTypesSelected
    this.consumptionTypesSelected = this.$store.getters.getConsumptionTypesSelected
    if(this.$store.getters.getNode > 0){
      this.getCommentsWithFilter()
    }
    if(this.comments.length == 0){
      if(this.$store.getters.getComments.length > 0){
        this.comments = this.$store.getters.getComments.results
        this.totalComments = this.$store.getters.getComments.count
        if(this.totalComments == 0){
          this.page = 0
        }
        this.pageCount = Math.ceil( this.totalComments / this.itemsPerPage )
        this.loading = false
      }
    }
  },
  watch: {
    filterApply: function(){
      this.getCommentsWithFilter()
    },
    pageCount: function(val){
      return val;
    },
    totalWidth: function() {
      return this.totalWidth
    }

  },
  methods: {
    setOrder(val) {
      this.ordering = val;
      this.page = 1;
      this.getComments()
    },
    goItemNextLevelCustom(item) {
      let itemCustom = {};
      itemCustom.id = item.center;
      itemCustom.name = item.center_name;
      itemCustom.level = 9;
      itemCustom.returnUrl = true;
      return this.goItemNextLevel(itemCustom, true);
    },
    downloadExcelCustom () {
      this.downloadExcel(this.getRequestParams(), this.totalComments)
    },
    // CUANDO APLICAMOS FILTRO POSICIONAMOS PAGINA INICIAL Y OBTENEMOS COMENTARIOS
    getCommentsWithFilter: function(){
      this.page = 1
      this.getComments()
    },
    // OBTENEMOS EL NOMBRE DEL TIPO DE SERVICIO
    getNameComsuptionTypes(val){
      let res = this.consumptionTypes.filter(element => element.value == val)
      if(res.length > 0){
        return res[0].text
      }
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
    onCheckCommentTypes(val) {
      this.commentTypesSelected = val
      this.$store.commit(types.SATISFACTION_FILTER_COMMENT_TYPES_SELECTED, val)
      this.getCommentsWithFilter()
    },
    onCheckCommentLanguage(val) {
      this.commentLanguageSelected = val
      this.$store.commit(types.SATISFACTION_FILTER_COMMENT_LANGUAGES_SELECTED, val)
      this.getCommentsWithFilter()
    },
    onCheckReasonTypes(val) {
      this.reasonTypesSelected = val
      this.$store.commit(types.SATISFACTION_FILTER_REASON_TYPES_SELECTED, val)
      this.getCommentsWithFilter()
    },
    onCheckConsumptionTypes(val) {
      this.consumptionTypesSelected = val
      this.$store.commit(types.SATISFACTION_FILTER_CONSUMPTION_TYPES_SELECTED, val)
      this.getCommentsWithFilter()
    },
    // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
    getComments: function(){
      const params = this.getRequestParams()
      this.loading = true
      SrvSatisfaction.getSatisfactionComments(params)
        .then(res => {
          if (res.status === 200) {
            // MAPEAR COMENTARIOS PARA AÑADIR CAMPO
            let custom = res.data.results
            this.comments = custom;
            this.totalComments = res.data.count
            this.$store.commit(types.SATISFACTION_COMMENTS, {results: custom, count: res.data.count})
            if(this.totalComments == 0){
              this.page = 0;
            }
            this.pageCount = Math.ceil( this.totalComments / this.itemsPerPage )
            this.loading = false
          }
        }, () => {
        })
    },
    // SE ACTUALIZA EL NÚMERO DE PAGINA
    handlePageChange(value) {
      this.page = value
      this.getComments()
    },
    // SE CARGAN DE NUEVO LOS COMENTARIOS MOSTRANDO POR PANTALLA EL NÚMERO DE ELEMENTOS SELECCIONADO
    handlePageSizeChange(size) {
      this.itemsPerPage = size
      this.page = 1
      this.getComments()
    },
    // PREPARAMOS LOS FILTROS A APLICAR EN LA LLAMADA AL API
    getRequestParams() {
      let params = {};
      if ((this.itemsPerPage * this.page) - this.itemsPerPage > 0) {
        params["offset"] = (this.itemsPerPage * this.page) - this.itemsPerPage
      }
      params["limit"] = this.itemsPerPage
      if(this.commentLanguageSelected){
        params["language"] = this.commentLanguageSelected
      }
      if(this.commentTypesSelected){
        params["sentiment"] = this.commentTypesSelected
      }
      if(this.reasonTypesSelected){
        params["reason"] = this.reasonTypesSelected
      }
      if(this.consumptionTypesSelected){
        params["survey_type"] = this.consumptionTypesSelected
      }
      params["ordering"] = this.ordering

      return params
    },
  }
}
</script>

<style lang="scss" scoped>
.categories{
  margin-bottom: 15px;
  .category{
    background-color: #eaeaea;
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 0.75rem;
    color:#fff;
    margin-right: 7px;
    &.cat-14{
      background-color: #379E91;
    }
    &.cat-5{
      background-color: #379E91;
    }
    &.cat-4{
      background-color: #CB655D;
    }
    &.cat-3{
      background-color: #744D60;
    }
    &.cat-2{
      background-color: #F59643;
    }
    &.cat-1{
      background-color: #919ECA;
    }
  }

}
.comentario{
  transition: all ease 0.3s;
  font-size: 0.813rem;
  margin-top: -4px;
}
.tipo{
  width: 15px;
  height: 15px;
  display: block;
  border-radius:3px;
}
.totalComments{
  display: inline-block;
  padding:5px 12px 3px 12px;
  font-size: 1.125rem;
  font-weight: 700;
}
.filters{
  padding:15px 0;
  border-bottom:1px solid #DFE3E6;
}

.boxOrder {
  float: right;
}
</style>
