<template>
<div class="noteQuestionPoll">
    <p>{{  labelsQuestionPoll[data[0]] }}</p>
    <starRatingMini :percent="data[1] / 5 * 100"></starRatingMini>
</div>
</template>
<script >
import starRatingMini from '@/components/common/starRatingMiniFive.vue'
import commonMixins from "@/mixins/common.js"
export default {
    components: {starRatingMini},
    mixins: [commonMixins],
    props: ['data'],
    data() {
        return {
            
        }
    }
}
</script>
<style scoped>
.noteQuestionPoll{
    border-radius: 4px;
    border: 1px solid #E2E3E4;
    text-align: center;
    font-size: 11px;
    color:#222226;
    padding: 6px 21px;
}
</style>